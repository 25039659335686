import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import Pagination from '../../../common/Page/Pagination';
import { convertDateInFormat, convertDateToLocalDatetime, getQueryData, getQueryDataUserProfile } from '../../../../utils';
import { useParams } from 'react-router-dom';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import Loader from '../../../common/Loader';
import { connect } from 'react-redux';
import { updateWelcomeLoginModal } from '../../../../store/Actions/Action';

const ModeratorDashboard = ({userData,csrf,updateWelcomeLoginModalHandler,islogin}) => {
    const [stageId, setStageId] = useState(null);
    const [enteredText, setEnteredText] = useState('');
    const { stageSlug } = useParams();
    
    const [selectedRadio, setSelectedRadio] = useState('registeredUser');
    const [stageTitle, setStageTitle] = useState("");
    const [chartData, setChartData] = useState([]);
    const [registeredUserData, setRegisteredUserData] = useState({});
    const [datasetsDownloadedData, setDatasetsDownloadedData] = useState({});
    const [solutionsSubmittedData, setSolutionsSubmittedData] = useState({});
    const [metrics, setMetrics] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [paginationDetails, setPaginationDetails] = useState({});
    const [searchedUserDetails, setSearchedUserDetails] = useState(null);

    const [iserror,setIsError] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [issuccess,setIsSuccess] = useState(false);
    const [successMessage,setSuccessMessage] = useState("");
    const [isSingleDay,setIsSingleDay] = useState(false);

    useEffect(()=>{
        let query = `query {
            stageData(stageSlug: "${stageSlug}") {
                id  
            }
        }`;
        getQueryData(query, "newcompetition")
        .then((data)=>{
            if(data.data.stageData) {
                setStageId(data.data.stageData?.id);
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    },[])

    useEffect(() => {
        const processChartData = (timeData, valueData) => {
            return Object.keys(timeData).map((key) => ({
                x: timeData[key],
                y: valueData[key],
            }));
        };

        const registeredUserDataPoints = processChartData(
            registeredUserData?.time || {},
            registeredUserData?.['registered users'] || {}
        );

        const datasetsDownloadedDataPoints = processChartData(
            datasetsDownloadedData?.time || {},
            datasetsDownloadedData?.['dataset downloaded'] || {}
        );

        const solutionsSubmittedDataPoints = processChartData(
            solutionsSubmittedData?.time || {},
            solutionsSubmittedData?.['solution submitted'] || {}
        );

        let dataToSet = [];
        switch (selectedRadio) {
            case "solutionsSubmitted":
                dataToSet = solutionsSubmittedDataPoints;
                break;
            case "datasetsDownloaded":
                dataToSet = datasetsDownloadedDataPoints;
                break;
            case "registeredUser":
            default:
                dataToSet = registeredUserDataPoints;
        }

        setChartData(dataToSet);
    }, [selectedRadio, registeredUserData, datasetsDownloadedData, solutionsSubmittedData]);

    const getChartData = (chartData) => {
        if (isSingleDay) {
            return chartData.map((point) => new Date(point.x).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        } else {
            return chartData.map((point) => new Date(point.x).toLocaleDateString());
        }
    };

    useEffect(() => {
        console.log("Chart Data:", chartData);

        if (window.Chart) {
            const ctx = document.getElementById('overallStatusChart').getContext('2d');
            const chartInstance = new window.Chart(ctx, {
                type: 'line',
                data: {
                    labels:getChartData(chartData),
                    datasets: [
                        {
                            data: chartData.map((point) => point.y),
                            borderColor: '#85D992',
                            borderWidth: 2,
                            fill: false,
                            cubicInterpolationMode: 'monotone',
                            pointRadius: 3,
                        },
                    ],
                },
                options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: '#FFFFFF', 
                        },
                        border: {
                            width: 2,
                            color: '#FFFFFF',
                        },
                        offset: true,
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: '#FFFFFF', 
                            stepSize: 1,
                            precision: 0
                        },
                        border: {
                            width: 2,
                            color: '#FFFFFF',
                        },
                        offset: true,
                    },
                },
                },
            });
            return () => {
                if (chartInstance) {
                    chartInstance.destroy();
                }
            };
        }
    }, [chartData]);

    const fetchData = async (page_number=1, per_page=10) => {
        try {
            const formData = new FormData();
            formData.append("platform", "newcompetition");
            formData.append("method", "GET");
            formData.append("forward_user_data", "True");
            formData.append("url", `api/moderator-dashboard/?stage_id=${stageId}&page=${page_number}&per_page=${per_page}`);
            formData.append('timeout', 100);
    
            const response = await fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                method: "POST",
                body: formData,
                credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
                headers: {
                    "X-CSRFToken": csrf,
                },
            });
    
            const data = await response.json();

            if(data.response.error) {
                setErrorMessage(data.response.error);
                setIsError(true);
                setTimeout(()=>{
                    setIsError(false);
                    setErrorMessage("");
                },3000);
            }

            if(data.response.status_code === 200){ 
                setStageTitle(data.response.stage_title);
                setMetrics(data.response.metrics);
                
                const chartsData = data.response.charts_data;

                setRegisteredUserData({
                    time: chartsData.registered_users_chart?.time || {},
                    "registered users": chartsData.registered_users_chart?.['registered users'] || {},
                });

                setDatasetsDownloadedData({
                    time: chartsData.dataset_downloaded_chart?.time || {},
                    "dataset downloaded": chartsData.dataset_downloaded_chart?.['dataset downloaded'] || {},
                });

                setSolutionsSubmittedData({
                    time: chartsData.solution_submitted_chart?.time || {},
                    "solution submitted": chartsData.solution_submitted_chart?.['solution submitted'] || {},
                });
                
                setUserDetails(data.response.user_details);
                setPaginationDetails(data.response.pagination);
                setIsSingleDay(data.response.is_single_day);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const updatePageNumber = (page_number) => {
        setPaginationDetails(prev => ({
            ...prev,
            current_page: page_number,
        }));
    
        fetchData(page_number, 10); 
    };

    const searchUser = async () => {
        try {
            const formData = new FormData();
            formData.append("platform", "newcompetition");
            formData.append("method", "GET");
            formData.append("forward_user_data", "True");
            formData.append("url", `api/moderator-dashboard-search/?stage_id=${stageId}&entered_text=${enteredText}`);

            const response = await fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                method: "POST",
                body: formData,
                credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
                headers: {
                    "X-CSRFToken": csrf,
                },
            });

            const data = await response.json();
            
            if(data.response.status_code === 200) {
                setSearchedUserDetails(data.response);
            }
        }
        catch(error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        let search_timeout;
    
        if (csrf && stageId) {
            if (enteredText !== "") {
                search_timeout = setTimeout(() => {
                    searchUser();
                }, 300);
            } else {
                fetchData(); 
                setSearchedUserDetails(null);
            }
        }
        return () => clearTimeout(search_timeout);
    }, [csrf, stageId, enteredText]);
    
    
    return (
        <>
            {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={successMessage} />}
            <Header />
            <section className='common-card-style common-style background-dark-primary pb-5 min-vh-100' id="leaderboard">
                <div className="container moderator-dashboard" id='hackathonDetailContent'>
                    <h2 className='text-white fs-40 fw-normal mb-5'>{stageTitle}</h2>
                    <h3 className='text-dark-secondary fs-20 mb-4'>Overall Stats</h3>
        
                    <div className='row gx-4 mb-5'>
                        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-2'>
                            <div className="card border border-dark p-4 h-100 text-white rounded-3">
                                <div className='card-body px-0 py-2'>
                                    <h5 className="fs-32 fw-bold mb-4">{metrics?.registered_users_count}</h5>
                                    <p className='mb-0 fs-20'>Registered Participants</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-2'>
                            <div className="card border border-dark p-4 h-100 text-white rounded-3">
                                <div className='card-body px-0 py-2'>
                                    <h5 className="fs-32 fw-bold mb-4">{metrics?.percentage_data_downloaded}% <span className='fs-14 fw-semibold'>Registered User</span></h5>
                                    <p className='mb-0 fs-20'>Dataset Downloaded</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-2'>
                            <div className="card border border-dark p-4 h-100 text-white rounded-3">
                                <div className='card-body px-0 py-2'>
                                    <h5 className="fs-32 fw-bold mb-4">{metrics?.percentage_solution_submitted}% <span className='fs-14 fw-semibold'>Registered User</span></h5>
                                    <p className='mb-0 fs-20'>Solution Submitted</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div class="form-check form-check-inline ps-0 pe-4">
                            <input class="form-check-input radio-style" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="registeredUser" checked={selectedRadio === 'registeredUser'} onChange={(e) => setSelectedRadio(e.target.value)}/>
                            <label class="form-check-label text-white w-100 rounded-4" for="inlineRadio1">Registered User</label>
                        </div>
                        <div class="form-check form-check-inline ps-0 pe-4">
                            <input class="form-check-input radio-style" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="datasetsDownloaded" checked={selectedRadio === 'datasetsDownloaded'} onChange={(e) => setSelectedRadio(e.target.value)}/>
                            <label class="form-check-label text-white w-100 rounded-4" for="inlineRadio2">Datasets Downloaded</label>
                        </div>
                        <div class="form-check form-check-inline ps-0">
                            <input class="form-check-input radio-style" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="solutionsSubmitted" checked={selectedRadio === 'solutionsSubmitted'} onChange={(e) => setSelectedRadio(e.target.value)}/>
                            <label class="form-check-label text-white w-100 rounded-4" for="inlineRadio3">Solutions Submitted</label>
                        </div>
                    </div>

                    <div className='row my-5'>
                        <canvas id="overallStatusChart"></canvas>
                    </div>

                    <div class="table-responsive my-5">
                        <div className='table-info d-flex align-items-center mb-4'>
                            <h5 className='text-dark-secondary col fs-24'>User Level Data</h5>
                            <div className="search-user d-flex flex-wrap bg-transparent px-2 py-1 align-items-center rounded-2">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.16667 15.8324C12.8486 15.8324 15.8333 12.8476 15.8333 9.16569C15.8333 5.48379 12.8486 2.49902 9.16667 2.49902C5.48477 2.49902 2.5 5.48379 2.5 9.16569C2.5 12.8476 5.48477 15.8324 9.16667 15.8324Z" stroke="white" stroke-width="1.48486" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.5078 17.498L13.8828 13.873" stroke="white" stroke-width="1.48486" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div>
                                    <input type="text" class="form-control bg-transparent text-dark-tertiary border-0" id="teamNameInput" value={enteredText} placeholder="Search User" autoComplete='off' onChange={(e) => setEnteredText(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <table class="table text-white background-dark-secondary table-striped">
                            <thead className='text-white border-bottom border-dark'>
                                <td>#</td>
                                <td>Username</td>
                                <td>DataSet Download</td>
                                <td>Solution Submitted</td>
                            </thead>
                        
                            {searchedUserDetails && <tr className='fs-18'>
                                <td className='fw-semibold'>#</td>
                                <td>
                                    <p className="bg-transparent m-0 p-0 text-dark-secondary text-truncate">{searchedUserDetails.username}</p>
                                    <p className="bg-transparent m-0 p-0 text-dark-tertiary text-truncate">{searchedUserDetails.email}</p>
                                </td>
                                <td className='text-dark-secondary'>
                                    {
                                        (searchedUserDetails.dataset_downloaded) ? "Yes" : "No"
                                    }
                                </td>
                                <td className='text-dark-secondary'>
                                    {
                                        (searchedUserDetails.solution_submitted) ? "Yes" : "No"
                                    }
                                </td>
                            </tr>}

                            {userDetails && userDetails.map((user, index) => (
                                <tr className='fs-18'>
                                    <td className='fw-semibold' key={index}>{index + 1}</td>
                                    <td>
                                        <p className="bg-transparent m-0 p-0 text-dark-secondary text-truncate">{user.username}</p>
                                        <p className="bg-transparent m-0 p-0 text-dark-tertiary text-truncate">{user.email}</p>
                                    </td>
                                    <td className='text-dark-secondary'>{user.dataset_downloaded}</td>
                                    <td className='text-dark-secondary'>{user.solution_submitted}</td>
                                </tr>
                            ))}
                        </table>
                    </div>

                    {paginationDetails && paginationDetails.total_pages && <Pagination updatePageNumber={updatePageNumber} last_page={paginationDetails.total_pages}/>}
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    userData:state.LoginModalReducer.userData,
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
    updateWelcomeLoginModalHandler:data=>{dispatch(updateWelcomeLoginModal(data))}
});

export default connect(mapStateToProps, mapDispatchToProps)(ModeratorDashboard);    