import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Admin from '../pages';
// import CodeExecute from '../pages/CodeExecute';
import PlatformDataUpdateForm from '../pages/PlatformDataUpdateForm';
import CustomForm from '../pages/CustomForm';
import Search from '../pages/Search';
import CreateTournament from '../pages/CreateTournament';
import CreateStage from '../pages/CreateStage';
import CreateTournamentStageMapping from '../pages/CreateTournamentStageMapping';
import DatahackAdmin from '../pages/DatahackAdmin';
import QuestionCreate from '../pages/QuestionCreate';
import QuizCreate from '../pages/QuizCreate';
import QuestionCreateFile from '../pages/QuestionCreateFile';
import CreateSpeaker from '../pages/CreateSpeaker';
import CreateDatahour from '../pages/CreateDatahour';
import CreateDatahourResource from '../pages/CreateDatahourResource';
import ProblemStatement from '../pages/ProblemStatement';
import CreateAttendee from '../pages/CreateAttendee';
import CreateVideo from '../pages/CreateVideo';
import Error404 from '../../../common/Alert/Error404';
import UpdateProblemStatement from '../pages/UpdateProblemStatement';
import Stage from '../pages/Stage';
import UpdateStage from '../pages/UpdateStage';
import CreateProblemStatement from '../pages/CreateProblemStatement';
import Tournament from '../pages/Tournament';
import UpdateTournament from '../pages/UpdateTournament';
import TournamentStageMapping from '../pages/TournamentStageMapping';
import UpdateTournamentStageMapping from '../pages/UpdateTournamentStageMapping';
import Quiz from '../pages/Quiz';
import UpdateQuiz from '../pages/UpdateQuiz';
import Question from '../pages/Question';
import Speaker from '../pages/Speaker';
import UpdateSpeaker from '../pages/UpdateSpeaker';
import UpdateQuestion from '../pages/UpdateQuestion';
import Datahour from '../pages/Datahour';
import UpdateDatahour from '../pages/UpdateDatahour';
import DatahourResource from '../../Datahack/pages/DatahourResource';
import UpdateDatahourResource from '../../Datahack/pages/UpdateDatahourResource';
import Attendee from '../pages/Attendee';
import UpdateAttendee from '../pages/UpdateAttendee';
import Video from '../pages/Video';
import UpdateVideo from '../pages/UpdateVideo';
import BundleEnrollment from '../pages/BundleEnrollment';
import LeadConversion from '../pages/LeadConversion';
import EvaluationMetric from '../pages/EvaluationMetric';
import CreateEvalutionMetric from '../pages/CreateEvalutionMetric';
import UpdateEvaluationMetric from '../pages/UpdateEvaluationMetric';
import Ranker from '../pages/Ranker';
import CreateRanker from '../pages/CreateRanker';
import UpdateRanker from '../pages/UpdateRanker';
import EmailTemplate from '../pages/EmailTemplate';
import CreateEmailTemplate from '../pages/CreateEmailTemplate';
import UpdateEmailTemplate from '../pages/UpdateEmailTemplate';
import PrivateRegistration from '../pages/PrivateRegistration';
import Loader from '../../../common/Loader';
import CloneTournament from '../pages/CloneTournament';
import GenerateDatahourCSV from '../pages/GenerateDatahourCSV';
import GenerateHackathonCSV from '../pages/GenerateHackathonCSV';
import GenerateSubmissionCSV from '../pages/GenerateSubmissionCSV';
import GenerateBlogathonCSV from '../pages/GenerateBlogathonCSV';
import TestimonialAndMentors from '../pages/TestimonialAndMentors';
import AVCCPanel from '../pages/AVCCPanel';
import ModeratorDashboard from '../pages/ModeratorDashboard';
import Unsubscribe from '../pages/Unsubscribe';
import UploadImageToS3 from '../pages/UploadImageToS3';
import SubscribedUser from '../pages/SubscribedUser';
import CourseSlugToCourseId from '../pages/CourseSlugToCourseId';
let PlatformContentViewer;

if (typeof document !== 'undefined') {
  PlatformContentViewer = React.lazy(() => import('../pages/PlatformContentViewer'));
}

export default function AdminRoutes() {
  return (
    <Routes>
        <Route path='/' exact element={<Admin />} />
        {/* Code Execute */}
        {/* <Route path='/code-execute' exact element={<CodeExecute />} /> */}
        {/* Platform Data Update */}
        <Route path='/platform-data-update' exact element={<PlatformDataUpdateForm/>} />
        <Route path='/platform-data-viewer' exact element={PlatformContentViewer ? <PlatformContentViewer/>:<Loader />} />
        <Route path='/platform-testimonial-update' exact element={<TestimonialAndMentors />} />
        {/* Custom Form */}
        <Route path="/custom-forms" exact element={<CustomForm />} />
        {/* Search */}
        <Route path="/search" exact element={<Search />} />
        {/* Datahack Admin*/}
        <Route path='/datahack-admin' exact element={<DatahackAdmin />}/>
        {/* Stage */}
        <Route path='/datahack-admin/stage' element={<Stage />} />
        <Route path='/datahack-admin/stage/:stageSlug' element={<UpdateStage />} />
        <Route path='/datahack-admin/stage/create-stage' element={<CreateStage />} />
        {/* Tournament */}
        <Route path='/datahack-admin/tournament' element={<Tournament />} />
        <Route path='/datahack-admin/tournament/:tournamentSlug' element={<UpdateTournament />} />
        <Route path='/datahack-admin/tournament/create-tournament' element={<CreateTournament />} />
        <Route path='/datahack-admin/clone-tournament/:tournamentId' element={<CloneTournament />}/>
        {/* Tournament Stage Mapping */}
        <Route path='/datahack-admin/tournament-stage-mapping' element={<TournamentStageMapping />}/>
        <Route path='/datahack-admin/tournament-stage-mapping/:stageSlug' element={<UpdateTournamentStageMapping />}/>
        <Route path='/datahack-admin/tournament-stage-mapping/create-tournament-stage-mapping' element={<CreateTournamentStageMapping />}/>
        {/* Quiz */}
        <Route path='/datahack-admin/quiz' element={<Quiz />}/>
        <Route path='/datahack-admin/quiz/:quizId' element={<UpdateQuiz />}/>
        <Route path='/datahack-admin/quiz/create-quiz' element={<QuizCreate />} />
        {/* Question */}
        <Route path='/datahack-admin/question' element={<Question />} />
        <Route path='/datahack-admin/question/:questionId' element={<UpdateQuestion />} />
        <Route path='/datahack-admin/question/create-question' element={<QuestionCreate />} />
        {/* Question File Upload */}
        <Route path='/datahack-admin/create-question-file' element={<QuestionCreateFile />} />
        {/* Problem Statement */}
        <Route path='/datahack-admin/problem-statement' exact element={<ProblemStatement />}/>
        <Route path='/datahack-admin/problem-statement/:stageId' exact element={<UpdateProblemStatement />}/>
        <Route path='/datahack-admin/problem-statement/create-problem-statement' exact element={<CreateProblemStatement />}/>
        {/* Generate CSV (Hackathon)  */}
        <Route path='/datahack-admin/generate-hackathon-csv' exact element={<GenerateHackathonCSV />}/>
        {/* Generate CSV (Submission)  */}
        <Route path='/datahack-admin/generate-submission-csv' exact element={<GenerateSubmissionCSV />}/>
        {/* Generate CSV (Blogathon)  */}
        <Route path='/datahack-admin/generate-blogathon-csv' exact element={<GenerateBlogathonCSV />}/>
        {/* Attendee */}
        <Route path='/datahack-admin/attendee' exact element={<Attendee />}/>
        <Route path='/datahack-admin/attendee/:attendeeId' exact element={<UpdateAttendee />}/>
        <Route path='/datahack-admin/attendee/create-attendee' exact element={<CreateAttendee />}/>
        {/* Video  */}
        <Route path='/datahack-admin/video' exact element={<Video />}/>
        <Route path='/datahack-admin/video/:episodeSlug' exact element={<UpdateVideo />}/>
        <Route path='/datahack-admin/video/create-video' exact element={<CreateVideo />}/>
        {/* Generate CSV (Datahour)  */}
        <Route path='/datahack-admin/generate-datahour-csv' exact element={<GenerateDatahourCSV />}/>
        {/* Speaker */}
        <Route path='/datahack-admin/speaker' exact element={<Speaker />} />
        <Route path='/datahack-admin/speaker/create-speaker' exact element={<CreateSpeaker />}/>
        <Route path='datahack-admin/speaker/:id' exact element={<UpdateSpeaker />}/>
        {/* Datahour */}
        <Route path='/datahack-admin/datahour' exact element={<Datahour />}/>
        <Route path='/datahack-admin/datahour/create-datahour' exact element={<CreateDatahour />}/>
        <Route path='/datahack-admin/datahour/:id/:slug' exact element={<UpdateDatahour />}/>
        {/* Datahour Resources */}
        <Route path='/datahack-admin/datahour-resources' exact element={<DatahourResource />}/>
        <Route path='/datahack-admin/datahour-resources/create-datahour-resources' exact element={<CreateDatahourResource />}/>
        <Route path='datahack-admin/datahour-resources/:id' exact element={<UpdateDatahourResource />} />
        {/* Evaluation Metric */}
        <Route path='/datahack-admin/evaluation-metric' exact element={<EvaluationMetric />}/>
        <Route path='/datahack-admin/evaluation-metric/create-evaluation-metric' exact element={<CreateEvalutionMetric />}/>
        <Route path='/datahack-admin/evaluation-metric/:id' exact element={<UpdateEvaluationMetric />}/>
        {/* Ranker  */}
        <Route path='/datahack-admin/ranker' exact element={<Ranker />}/>
        <Route path='/datahack-admin/ranker/create-ranker' exact element={<CreateRanker/>}/>
        <Route path='/datahack-admin/ranker/:id' exact element={<UpdateRanker/>}/>
        {/* Private Registration */}
        <Route path='/datahack-admin/private-registration' exact element={<PrivateRegistration />}/>
        {/* Bundle Enrollment  */}
        <Route path='/bundle-enrollment' exact element={<BundleEnrollment />}/>
        {/* Lead Conversion  */}
        <Route path='/lead-conversion' exact element={<LeadConversion />}/>
        {/* Email Template  */}
        <Route path='/email-template' exact element={<EmailTemplate />}/>
        <Route path='/email-template/create-email-template' element={<CreateEmailTemplate />}/>
        <Route path='/email-template/:templateId' element={<UpdateEmailTemplate />}/>
        {/* Avcc members */}
        <Route path='/datahack-admin/avcc-panel' element={<AVCCPanel />}/>
        {/* Moderator Dashboard */}
        <Route path='/datahack-admin/moderator/:stageSlug' exact element={<ModeratorDashboard />} />
        {/* upload image to s3 */}
        <Route path='/upload-image-to-s3' exact element={<UploadImageToS3 />} />
        {/* Error Page */}
        <Route path='*' element={<Error404 />} />
        {/* Unsubscribe Form */}
        <Route path='/unsubscribe' exact element={<Unsubscribe />} />
        {/* Subscribed User Filter */}
        <Route path='/subscribed-users' exact element={<SubscribedUser />}/>
        {/*  */}
        <Route path='/course-slug-to-thinkific-id-mapping' exact element={<CourseSlugToCourseId />}/>
    </Routes>
  )
}
