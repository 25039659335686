import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common/Header/Header';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const Unsubscribe = ({ islogin, csrf }) => {
    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [userEmail, setUserEmail] = useState("");

    const resetFormFields = () => {
        setUserEmail("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let payload = {
            "user_email" : userEmail
        }

        const formData = new FormData();
        formData.append("platform", 'newnotifications');
        formData.append("payload", JSON.stringify(payload));
        formData.append("url", 'api/customFormUnsubscribe');
        formData.append("forward_user_data", "True");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (!result.errors) {
                const { message, status_code } = result.response;

                if (status_code === 200) {
                    setMessage(message); 
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                        resetFormFields();
                    }, 3000);
                } else if (status_code === 403) {
                    setMessage(message);
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            } else {
                setMessage(result.errors[0].message);
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            setMessage("Some error occured!");
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
        });
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={message} />}
            <section id='unsubscribeUserEmail' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-bold text-uppercase">Unsubscribe Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Email <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="email"
                                            id='userEmail'
                                            className='form-control'
                                            placeholder='Enter Email'
                                            value={userEmail}
                                            onChange={(event) => setUserEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>Unsubscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
    islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);