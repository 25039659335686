import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getCSRF } from '../../../../utils/Login';
import { isValidURL } from '../../../../utils/InputValidation';
import $ from 'jquery';

function CourseSlugToCourseId({csrf,islogin}) {
    const [courseId,setCourseId] = useState("");
    const [courseSlug,setCourseSlug] = useState("");
    const [thinkificId,setThinkificId] = useState("");
    const [courseSlugToThinkificIdMappingData,setCourseSlugToThinkificIdMappingData] = useState(null);

    useEffect(()=>{
        if(islogin){
            getCSRF()
            .then((data)=>{
                getAllCourses(data);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])

    const getAllCourses = async (csrfTemp) => {
        try {
            const response = await fetch(process.env.REACT_APP_ALL_COURSE, {
                method: "GET",
                headers: {
                    "X-CSRFToken":csrfTemp ? csrfTemp:csrf,
                },
                credentials:process.env.REACT_APP_CREDENTIAL_TYPE
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setCourseSlugToThinkificIdMappingData(data.response);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    }
    
    const openModal = (id,slug,thinkificid) => {
        setCourseId(id);
        setCourseSlug(slug);
        setThinkificId(thinkificid);
        window.$("#createAndUpdateModal").modal("show");
    }

    const updateCourse = async () => {
        if(!isValidURL(courseSlug)){
            $(".warning").remove();
            $("#courseSlug").parent().append(`<small class="text-danger warning">Please enter a valid course Url</small>`);
            setTimeout(() => {
                $(".warning").slideUp(300, function() {
                    $(this).remove();
                });
            }, 3000);
            return false;
        }

        if(thinkificId.trim() == ""){
            $(".warning").remove();
            $("#thinkficId").parent().append(`<small class="text-danger warning">Please enter a valid course Url</small>`);
            setTimeout(() => {
                $(".warning").slideUp(300, function() {
                    $(this).remove();
                });
            }, 3000);
            return false;
        }

        const formData = new FormData();
        formData.append("course_url",courseSlug);
        formData.append("course_id",thinkificId);
        let url = process.env.REACT_APP_CREATE_COURSE;
    
        if(courseId !== ""){
            formData.append("course_obj_id",courseId);
            url = process.env.REACT_APP_UPDATE_COURSE;
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "X-CSRFToken":csrf,
                },
                credentials:process.env.REACT_APP_CREDENTIAL_TYPE,
                body:formData
            });
    
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`${data.message}`);
            }
            else{
                window.$("#createAndUpdateModal").modal("hide");
                getAllCourses();
            }
            console.log(data)
        } catch (error) {
            alert( error.message)
            console.error("Error fetching courses:", error.message);
        }
    }

    return (
        <> 
            <Header />
            <section id="courseSlugToThinkificIdMapping" className='common-style min-vh-100 background-dark-primary'>
                <div className="container">
                    <button className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table' onClick={()=>{openModal("","","")}}>
                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        Create New Course
                    </button>
                    <div className="row">
                        <div className="table-responsive">
                                <table className="table table-striped text-center table-dark">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Course Url</th>
                                            <th>Thinkific Id</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courseSlugToThinkificIdMappingData?.map((obj)=>{
                                            return(
                                                <tr key={obj.id}>
                                                    <td>{obj.id}</td>
                                                    <td>{obj.course_url}</td>
                                                    <td>{obj.course_id}</td>
                                                    <td>
                                                        <button className='btn btn-dark btn-dark-primary px-4 py-2' onClick={()=>{openModal(obj.id,obj.course_url,obj.course_id)}}>Update</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                        </div>
                        {/* {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateProblemStatementByPageNumber} />} */}
                    </div>
                </div>
            </section>
            <div className="modal fade" id="createAndUpdateModal" tabindex="-1" aria-labelledby="createAndUpdateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content background-dark-primary">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div class="mb-3">
                                <label for="courseSlug" class="form-label text-white">Course Url</label>
                                <input type="text" class="form-control background-dark-secondary border border-dark text-white" id="courseSlug" value={courseSlug} onChange={(e)=>{setCourseSlug(e.target.value)}} />
                            </div>
                            <div class="mb-3">
                                <label for="thinkficId" class="form-label text-white">Thinkific Id</label>
                                <input type="text" class="form-control background-dark-secondary border border-dark text-white" id="thinkficId" value={thinkificId} onChange={(e)=>{setThinkificId(e.target.value)}} />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-dark btn-dark-primary px-4 py-2 w-100" onClick={updateCourse}>{courseId == ""?"Create":"Update"} Course</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseSlugToCourseId);
