import React, { useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import Loader from '../../../common/Loader';

const SubscribedUser = ({ csrf }) => {
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false); 
    
    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [report, setReport] = useState(null);

    const resetFormFields = () => {
        fileInputRef.current.value = '';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return; 
        setLoading(true);

        const formData = new FormData();
        formData.append("platform", 'newnotifications');
        formData.append("file", fileInputRef.current.files[0]);
        formData.append("url", 'api/filterEmails');
        formData.append("forward_user_data", "True");
        formData.append('timeout', 100);

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body: formData,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then((response) => {
            if (!response.ok) {
                setMessage(response?.response?.message);
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                setLoading(false); 
                return false;
            }
            return response.json();
        })
        .then(result => {
            if (result && result?.response?.status_code == 200) {
                setReport(result?.response);
                setMessage(result?.response?.message); 
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                    resetFormFields();
                }, 3000);
            }
            else {
                setMessage(result?.response?.error);
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    return (
        <>
            {loading && <Loader />}
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={message} />}
            <section id='subscribedUserFilter' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-3 text-center text-white fw-semibold text-uppercase">Subscribed User Filter Form</h3>

                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group my-5">
                                            <label htmlFor="codeFile" className="me-3 text-white">CSV File: <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="codeFile"
                                                ref={fileInputRef}
                                                required
                                            />
                                        </div>
                                        <button type="submit" disabled={loading} className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            {loading ? 'Filtering Subscribed Users...' : 'Filter'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {report && <div className='row d-flex justify-content-center align-items-center pt-3'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-5 text-center text-white fw-semibold text-uppercase">Reports</h3>

                                    <div className='text-white'>
                                        <ul>
                                            <li className='mb-4'>
                                                Subscribed Users File | Size: <span className='fw-semibold'>{report?.subscribed_file_size} MB </span>
                                                | Shape: <span className='fw-semibold'>{"{"+report?.subscribed_row_count} , {report?.subscribed_col_count+"}"}</span>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 d-flex align-items-center justify-content-center w-100 mt-3" href={report?.subscribed_file_url} download>
                                                    Download Report
                                                    <svg className='ms-auto' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="download-01">
                                                            <path id="Icon" d="M14 10.0918V10.8918C14 12.0119 14 12.572 13.782 12.9998C13.5903 13.3761 13.2843 13.6821 12.908 13.8738C12.4802 14.0918 11.9201 14.0918 10.8 14.0918H5.2C4.07989 14.0918 3.51984 14.0918 3.09202 13.8738C2.71569 13.6821 2.40973 13.3761 2.21799 12.9998C2 12.572 2 12.0119 2 10.8918V10.0918M11.3333 6.75846L8 10.0918M8 10.0918L4.66667 6.75846M8 10.0918V2.0918" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>

                                            <li className='mb-4'>
                                                Unsubscribed Users File | Size: <span className='fw-semibold'>{report?.unsubscribed_file_size} MB </span>
                                                | Shape: <span className='fw-semibold'>{"{"+report?.unsubscribed_row_count} , {report?.unsubscribed_col_count+"}"}</span>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 d-flex align-items-center justify-content-center w-100 mt-3" href={report?.unsubscribed_file_url} download>
                                                    Download Report
                                                    <svg className='ms-auto' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="download-01">
                                                            <path id="Icon" d="M14 10.0918V10.8918C14 12.0119 14 12.572 13.782 12.9998C13.5903 13.3761 13.2843 13.6821 12.908 13.8738C12.4802 14.0918 11.9201 14.0918 10.8 14.0918H5.2C4.07989 14.0918 3.51984 14.0918 3.09202 13.8738C2.71569 13.6821 2.40973 13.3761 2.21799 12.9998C2 12.572 2 12.0119 2 10.8918V10.0918M11.3333 6.75846L8 10.0918M8 10.0918L4.66667 6.75846M8 10.0918V2.0918" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribedUser);