import React from 'react';
import Header from '../../../common/Header/Header';

export default function Admin() {
  return (
    <>
      <Header />
      <section id="admin" className='background-dark-primary common-style min-vh-100'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="card p-4 mx-auto background-dark-tertiary">
                <div className="card-body">
                  <h3 className="card-title text-center mb-3 text-white text-uppercase fw-semibold">AV Admin</h3>

                  {/* <a href={"/av-admin/code-execute"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Code Execute</a> */}
                  <a href={"/av-admin/platform-data-viewer"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Platform Data Update</a>
                  {/* <a href={"/av-admin/platform-data-update"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Platform Offer Update</a> */}
                  {/* <a href={"/av-admin/platform-testimonial-update"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Platform Testimonial Update</a> */}
                  {/* <a href={"/av-admin/custom-forms"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Custom Form</a> */}
                  {/* <a href={"/av-admin/search"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Search</a> */}
                  <a href={"/av-admin/upload-image-to-s3"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Upload Image to S3</a>
                  <a href={"/av-admin/datahack-admin"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Datahack Admin</a>
                  <a href={"/av-admin/bundle-enrollment"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Bundle Enrollment</a>
                  <a href={"/av-admin/lead-conversion"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Lead Conversion</a>
                  <a href={"/av-admin/email-template"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Email Template</a>
                  <a href={"/av-admin/unsubscribe"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Unsubscribe Form</a>
                  <a href={"/av-admin/subscribed-users"} className='btn btn-dark btn-dark-primary w-100 mt-3'>Subscribed User Filter</a>
                  <a href={'/av-admin/course-slug-to-thinkific-id-mapping'} className='btn btn-dark btn-dark-primary w-100 mt-3'>Course slug To Thinkific Id Mapping</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
